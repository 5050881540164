<template>
	<!-- 批量下载不显示该插件 -->
	<!-- v-show="downLoadArray.length<=0" -->
	<div v-show="downLoadArray.length <= 0" class="prefabCode" style="position: relative">
		<div style="background-color: #f6f6f6; text-align: center; padding: 10px 0px">
			<div ref="selfQr">
				<canvas v-if="styleJsonStr" ref="canvas" width="180px" height="180px"></canvas>
				<canvas v-else ref="canvas" width="300px" height="300px"></canvas>
				<hr v-if="!(styleJsonStr && bgStyle == 1)" :style="{ color: ' #cccccc', opacity: bgStyle != 1 ? 1 : 0 }" />
				<div v-if="!(styleJsonStr && bgStyle == 1)" :style="{ marginBottom: '10px', opacity: bgStyle != 1 ? 1 : 0 }">{{ content }}</div>
			</div>
			<div v-if="!styleJsonStr">
				<div style="display: flex; flex-direction: row">
					<div style="flex: 1">
						<div style="width: 40%; font-size: 14px; padding: 5px">码设置:</div>
						<el-radio-group v-model="radio3" size="small">
							<el-radio-button label="颜色设置"></el-radio-button>
							<el-radio-button label="嵌入logo"></el-radio-button>
							<el-radio-button label="嵌入文字"></el-radio-button>
							<el-radio-button label="其他设置"></el-radio-button>
						</el-radio-group>
						<div v-if="radio3 == '颜色设置'" style="height: 75px">
							<div style="margin: 10px" />
							<div style="display: flex; flex-direction: row; align-items: center; justify-content: center"><span>前景色</span><input type="color" v-model="color" @change="drawWithStyleGet" /> <span style="margin-left: 10px">背景色</span><input type="color" v-model="bgcolor" @change="drawWithStyleGet" /></div>
							<!-- <span>渐变色</span><input type="color" v-model="gradientColor" @change="drawQRCode" /> -->
						</div>
						<div v-if="radio3 == '嵌入logo'" style="height: 75px">
							<div style="margin: 10px" />
							<el-radio-group @change="drawWithStyleGet" v-model="picStyle">
								<el-radio :label="1">圆角</el-radio>
								<el-radio :label="2">白底</el-radio>
								<el-radio :label="3">描边</el-radio>
								<el-radio :label="4">原图</el-radio>
							</el-radio-group>
							<div style="margin: 10px" />
							<input id="fileInput" type="file" @change="onFileChange" />
							<el-button @click="delImage" size="small" circle v-if="image" type="text" icon="el-icon-delete"></el-button>
						</div>
						<div v-if="radio3 == '嵌入文字'" style="height: 75px">
							<div style="margin: 10px" />
							<!-- @input="drawQRCode" -->
							<el-input size="mini" placeholder="请输入文字" style="margin-right: 10px; width: 200px" clearable v-model="inputWord"></el-input>
							<el-button @click="drawWithStyleGet" size="mini" type="primary">确认</el-button>
							<div style="margin: 10px" />
							<div style="display: flex; flex-direction: row; align-items: center; justify-content: center">
								<span style="margin: 5px">效果 </span>
								<el-select style="width: 100px" @change="drawWithStyleGet" v-model="fontStyle.style" size="mini" placeholder="请选择">
									<el-option label="描边" value="stroke"> </el-option>
									<!-- <el-option
							label="3D"
							value="3D">
							</el-option>
							<el-option
							label="发光"
							value="light">
							</el-option> -->
								</el-select>
								<span style="margin: 5px">字号</span
								><el-select style="width: 100px" @change="drawWithStyleGet" v-model="fontStyle.size" size="mini" placeholder="请选择">
									<el-option v-for="item in fontSize" :key="item.value" :label="item.value" :value="item.value"> </el-option>
								</el-select>
								<span style="margin: 5px">文字颜色</span><input type="color" v-model="fontStyle.color" @change="drawWithStyleGet" />
							</div>
						</div>
						<div v-if="radio3 == '其他设置'" style="height: 75px">
							<div style="margin: 10px" />
							<div style="display: flex; flex-direction: row; align-items: center; justify-content: center">
								<span style="margin: 5px">外边距</span
								><el-select style="width: 100px" @change="drawWithStyleGet" v-model="otherSet.marginSize" size="mini" placeholder="请选择">
									<el-option v-for="item in marginSizeArr" :key="item.value" :label="item.value" :value="item.value"> </el-option>
								</el-select>
								<span style="margin: 5px">纠错等级</span
								><el-select style="width: 100px" @change="drawWithStyleGet" v-model="otherSet.level" size="mini" placeholder="请选择">
									<el-option v-for="item in qrLevelArr" :key="item.value" :label="item.label" :value="item.value"> </el-option>
								</el-select>
							</div>
						</div>
					</div>
					<div style="flex: 1; display: flex; flex-direction: column">
						<div style="width: 40%; font-size: 14px; padding: 5px">边框设置:</div>
						<div style="height: 75px">
							<div style="margin: 10px" />
							<span class="qrBorder">边框:</span>
							<el-radio-group @change="drawWithStyle" v-model="bgStyle">
								<el-radio :label="1">保留</el-radio>
								<el-radio :label="2">去掉</el-radio>
							</el-radio-group>
							<div style="margin: 10px" />
							<span class="qrBorder">颜色:</span>
							<el-radio-group @change="drawQRCodeWithbg" v-model="bgColorStyle">
								<el-radio :disabled="bgStyle != 1" :label="1">蓝色</el-radio>
								<el-radio :disabled="bgStyle != 1" :label="2">红色</el-radio>
							</el-radio-group>
							<div style="margin: 10px" />
						</div>
						<span style="width: 40%; font-size: 14px; padding: 5px">信息设置:</span>
						<el-checkbox-group style="display: inline-block" @change="drawQRCodeWithbg" v-model="infoList">
							<el-checkbox :disabled="bgStyle != 1" label="标识编号"></el-checkbox>
							<el-checkbox :disabled="bgStyle != 1" label="型号"></el-checkbox>
						</el-checkbox-group>
					</div>
				</div>
				<div style="margin-top: 10px">
					<el-button size="mini" class="mBtn2" @click="clearSet">清除设置 </el-button>
					<el-button size="mini" class="mBtn1" @click="sureQrMsg">确认设置 </el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import QRCode from "qrcode";
import JSZip from "jszip";
import $axios from "axios";
import html2canvas from "html2canvas";
export default {
	props: {
		// 父组件传递过来的值
		styleJsonStr: {
			type: String,
			default: "",
		},
		// 下载的二维码数组
		downLoadArray: {
			type: Array,
			default: () => [],
		},
		batchNumber: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			bgImageBlue: require("@/assets/images/p_bg_blue.png"),
			bgImageRed: require("@/assets/images/p_bg_red.png"),
			imgFile: null,
			fontSize: [
				{
					value: "12px",
				},
				{
					value: "14px",
				},
				{
					value: "16px",
				},
				{
					value: "18px",
				},
				{
					value: "20px",
				},
				{
					value: "22px",
				},
				{
					value: "24px",
				},
				{
					value: "26px",
				},
				{
					value: "28px",
				},
				{
					value: "30px",
				},
				{
					value: "32px",
				},
				{
					value: "34px",
				},
			],
			qrLevelArr: [
				{
					label: "最低",
					value: "L",
				},
				{
					label: "低",
					value: "M",
				},
				{
					label: "中",
					value: "Q",
				},
				{
					label: "高",
					value: "H",
				},
			],
			marginSizeArr: [
				{
					value: 1,
				},
				{
					value: 2,
				},
				{
					value: 3,
				},
				{
					value: 4,
				},
				{
					value: 5,
				},
				{
					value: 6,
				},
				{
					value: 7,
				},
				{
					value: 8,
				},
				{
					value: 9,
				},
				{
					value: 10,
				},
			],
			picStyle: 4, //图片设置
			radio3: "颜色设置",
			canvasW: "",
			// gradientColor: '',
			//start动态数据
			otherSet: {
				level: "H",
				marginSize: 1,
			},
			fontStyle: {
				style: "stroke",
				color: "#000000",
				size: "28px",
			},
			color: "#000000",
			bgcolor: "#FFFFFF",
			image: null,
			content: "88.118.8/1662314952154812416",
			inputWord: "",
			//end动态数据

			// 新需求
			bgStyle: 1,
			bgColorStyle: 1,
			infoList: ["标识编号", "型号"],
			model: "AD6545545224",

			// 批量下载
			qrCodes: [],
			fileNameArr: [],
			picNameArr: [],
		};
	},
	mounted() {
		let _self = this;

		// 功能:批量下载
		if (this.downLoadArray.length > 0) {
			let array = [];
			this.getArrayData(array).then(() => {
				for (let i = 0; i < array.length; i++) {
					let item = array[i];
					let params = JSON.parse(item.filestyleMsg);
					if (params.bgStyle == 1) {
						_self.drawDownQRCodeWithBg(item.content, item.filestyleMsg, item.folderName, item.model).then((res) => {
							//  截取folderName从"/"开始的字符串
							let folderName = item.folderName.substring(item.folderName.indexOf("/") + 1);
							// console.log('???s',folderName);
							_self.qrCodes.push(res);
							_self.fileNameArr.push(item.model);
							_self.picNameArr.push(folderName);
							if (i == array.length - 1) {
								_self.downloadZipFile();
							}
						});
					} else {
						_self.drawDownQRCode(item.content, item.filestyleMsg, item.folderName, item.model).then((res) => {
							let folderName = item.folderName.substring(item.folderName.indexOf("/") + 1);
							// console.log('???',folderName);
							_self.qrCodes.push(res);
							_self.fileNameArr.push(item.model);
							_self.picNameArr.push(folderName);
							if (i == array.length - 1) {
								_self.downloadZipFile();
							}
						});
					}
				}
			});
		} else {
			// 功能:二维码详情
			if (this.styleJsonStr) {
				let styleJson = JSON.parse(this.styleJsonStr);
				this.otherSet = styleJson.otherSet;
				this.fontStyle = styleJson.fontStyle;
				this.color = styleJson.color;
				this.bgcolor = styleJson.bgcolor;
				this.image = styleJson.image;
				this.content = styleJson.content;
				this.inputWord = styleJson.inputWord;

				this.canvasW = "180px";

				this.bgStyle = styleJson?.bgStyle ? styleJson?.bgStyle : 0;

				if (this.bgStyle == 1) {
					this.bgColorStyle = styleJson.bgColorStyle;
					this.infoList = styleJson.infoList;
					this.model = styleJson.model;
					this.str2drawQrCodeWithbg();
				} else {
					this.str2drawQrCode();
				}
				// console.log("styleJson", styleJson,"this.image",this.image);
			} else {
				// 功能:新增二维码
				_self._http({
					// url: "/v1/batchCoding/getStyle",
					url: "/v1/batch/coding/getStyle",
					type: "get",
					// isBody: true,
					// data: {style: styleMsg},
					success: function (res) {
						if (res.data && res.data.style) {
							let styleJson = JSON.parse(res.data.style);
							_self.otherSet = styleJson.otherSet;
							_self.fontStyle = styleJson.fontStyle;
							_self.color = styleJson.color;
							_self.bgcolor = styleJson.bgcolor;
							_self.image = styleJson.image;
							_self.content = styleJson.content;
							_self.inputWord = styleJson.inputWord;
							_self.canvasW = "300px";

							_self.bgStyle = styleJson?.bgStyle ? styleJson?.bgStyle : 2;

							if (_self.bgStyle == 1) {
								_self.infoList = styleJson?.infoList ? styleJson?.infoList : [];
								_self.bgColorStyle = styleJson?.bgColorStyle ? styleJson?.bgColorStyle : 1;
								_self.drawQRCodeWithbg();
							} else {
								_self.drawQRCode();
							}
						} else {
							if (_self.bgStyle == 1) {
								_self.drawQRCodeWithbg();
							} else {
								_self.canvasW = "300px";
								_self.drawQRCode();
							}
						}
					},
				});
			}
		}
	},
	methods: {
		async getArrayData(array) {
			for (let i = 0; i < this.downLoadArray.length; i++) {
				for (let j = 0; j < this.downLoadArray[i].signs.length; j++) {
					array.push({
						content: this.downLoadArray[i].signs[j].signUrl,
						folderName: this.downLoadArray[i].signs[j].sign,
						model: this.downLoadArray[i].modelName,
						// 产品需求1.2.0订单列表>标识详情>下载二维码，默认二维码样式
						filestyleMsg: this.downLoadArray[i].style?this.downLoadArray[i].style:'{"otherSet":{"level":"H","marginSize":1},"fontStyle":{"style":"stroke","color":"#000000","size":"28px"},"color":"#000000","bgcolor":"#FFFFFF","image":"","content":" 88.118.8/1662314952154812416","inputWord":""}',
					});
				}
			}
		},
		async downloadZipFile() {
			setTimeout(() => {
				this.laterDown();
			}, 800);
		},
		async laterDown() {
			let _self = this;
			const zip = new JSZip();
			for (let i = 0; i < _self.qrCodes.length; i++) {
				const fileName = `${_self.fileNameArr[i]}/${_self.picNameArr[i]}.png`;
				zip.file(fileName, _self.qrCodes[i].split("base64,")[1], { base64: true });
			}

			const content = await zip.generateAsync({ type: "blob" });
			const zipFileUrl = URL.createObjectURL(content);

			const link = document.createElement("a");
			link.href = zipFileUrl;
			let date = new Date().toLocaleDateString();
			link.download = this.batchNumber || `${date.replaceAll("\\", "_")}.zip`;
			link.click();

			URL.revokeObjectURL(zipFileUrl);
			_self.qrCodes = [];
			_self.picNameArr = [];
			_self.fileNameArr = [];
			_self.downFileing = false;
		},
		sureQrMsg() {
			let _self = this;
			if (_self.imgFile) {
				let fileFormData = new FormData();
				fileFormData.append("file", _self.imgFile);
				let config = { headers: { "Content-Type": "multipart/form-data" } };
				$axios.post("/v1/productCommon/uploadFile"+'?parentRoute='+'selfDesignQr', fileFormData, config).then(function (res) {
					if (res.data.success) {
						let fileUrl = res.data.data.fileSign
						if (res.data.data.fileSign.indexOf("secondary.csin.net.cn") != -1) {
							let fileSign = res.data.data.fileSign;
							let fileSignArr = fileSign.split("/");
							fileSignArr[2] = "pdm.csin.net.cn";
							fileSign = fileSignArr.join("/");
							fileUrl = fileSign;
						}

						let params = {
							otherSet: _self.otherSet,
							fontStyle: _self.fontStyle,
							color: _self.color,
							bgcolor: _self.bgcolor,
							image: fileUrl,
							content: _self.content,
							inputWord: _self.inputWord,
						};
						if (_self.bgStyle == 1) {
							_self.$set(params, "bgStyle", _self.bgStyle);
							_self.$set(params, "bgColorStyle", _self.bgColorStyle);
							_self.$set(params, "infoList", _self.infoList);
							_self.$set(params, "model", _self.model);
						}
						let str = JSON.stringify(params);
						_self.$emit("self_style", str);
					} else {
						_self.$message.error(res.data.info);
					}
				});
			} else {
				let params = {
					otherSet: this.otherSet,
					fontStyle: this.fontStyle,
					color: this.color,
					bgcolor: this.bgcolor,
					// image: res.data.fileSign,
					content: this.content,
					inputWord: this.inputWord,
				};
				// if (typeof _self.image == "string") {
				// 	console.log("string");
				// } else {
				// 	console.log("File");
				// }

				if (_self.image && _self.image.indexOf("https") != -1) {
					_self.$set(params, "image", _self.image);
				}
				if (_self.bgStyle == 1) {
					_self.$set(params, "bgStyle", _self.bgStyle);
					_self.$set(params, "bgColorStyle", _self.bgColorStyle);
					_self.$set(params, "infoList", _self.infoList);
					_self.$set(params, "model", _self.model);
				}
				let str = JSON.stringify(params);
				_self.$emit("self_style", str);
			}
		},
		fDevelop() {
			this.$message.warning("功能开发中");
		},
		clearSet() {
			if (document.getElementById("fileInput")?.value) {
				document.getElementById("fileInput").value = "";
			}
			this.otherSet = {
				level: "H",
				marginSize: 1,
			};
			this.fontStyle = {
				style: "stroke",
				color: "#000000",
				size: "28px",
			};
			this.picStyle = 4; //图片设置
			this.canvasW = this.styleJsonStr ? "180px" : "300px";
			this.color = "#000000";
			this.bgcolor = "#FFFFFF";
			this.image = null;
			this.imgFile = null;
			this.content = " 88.118.8/1662314952154812416";
			this.inputWord = "";

			// 1.1新增
			this.bgStyle = 1;
			this.bgColorStyle = 1;
			this.infoList = ["标识编号", "型号"];
			this.model = "AD6545545224";

			this.drawWithStyleGet();
		},
		delImage() {
			document.getElementById("fileInput").value = "";
			this.image = null;
			this.imgFile = null;
			this.drawWithStyleGet();
		},
		drawQRCode() {
			let _self = this;
			const canvas = this.$refs.canvas;
			const context = canvas.getContext("2d");
			const size = canvas.width;
			const color = this.color;
			const bgcolor = this.bgcolor;
			const qrLevel = this.otherSet.level;
			const marginSize = this.otherSet.marginSize;
			var opts = {
				errorCorrectionLevel: qrLevel,
				type: "image/jpeg",
				width: size,
				quality: 0.3,
				margin: marginSize,
				color: {
					dark: color,
					// dark:`linear-gradient(90deg, #47479d 0%, red 100%);`,
					light: bgcolor,
				},
			};

			QRCode.toDataURL(_self.content, opts, (err, url) => {
				if (err) throw err;

				const img = new Image();
				img.src = url;
				img.onload = () => {
					context.drawImage(img, 0, 0, size, size);

					let isUrl = false;
					//判断_self.image是否是string
					if (typeof _self.image == "string") {
						isUrl = true;
					}
					// if (_self.image?.toString().indexOf("https") != -1) {
					// 	isUrl = true;
					// }

					if (_self.image) {
						// 圆角
						if (_self.picStyle == 1) {
							const radius = size / 6;
							context.save();
							context.beginPath();
							context.arc(size / 2, size / 2, radius, 0, 2 * Math.PI);
							context.clip();
							if (isUrl) {
								let img = new Image();
								img.src = _self.image;
								img.onload = () => {
									context.drawImage(img, (3 * size) / 8, (3 * size) / 8, size / 4, size / 4);
									context.restore();
									haveInputWord();
								};
							} else {
								context.drawImage(_self.image, (3 * size) / 8, (3 * size) / 8, size / 4, size / 4);
								context.restore();
								haveInputWord();
							}
						} else if (_self.picStyle == 2) {
							const borderWidth = size / 40;
							if (isUrl) {
								let img = new Image();
								img.src = _self.image;
								img.onload = () => {
									context.drawImage(img, (3 * size) / 8, (3 * size) / 8, size / 4, size / 4);
									context.strokeStyle = "white";
									context.lineWidth = borderWidth;
									context.strokeRect((3 * size) / 8, (3 * size) / 8, size / 4, size / 4);
									haveInputWord();
								};
							} else {
								context.drawImage(_self.image, (3 * size) / 8, (3 * size) / 8, size / 4, size / 4);
								context.strokeStyle = "white";
								context.lineWidth = borderWidth;
								context.strokeRect((3 * size) / 8, (3 * size) / 8, size / 4, size / 4);
								haveInputWord();
							}
						} else {
							if (isUrl) {
								let img = new Image();
								img.src = _self.image;
								img.onload = () => {
									context.drawImage(img, (3 * size) / 8, (3 * size) / 8, size / 4, size / 4);
									haveInputWord();
								};
							} else {
								context.drawImage(_self.image, (3 * size) / 8, (3 * size) / 8, size / 4, size / 4);
								haveInputWord();
							}
						}
					}

					function haveInputWord() {
						if (_self.inputWord) {
							if (_self.fontStyle.style == "stroke") {
								context.strokeStyle = "white";
								context.lineWidth = 10;
								context.font = _self.fontStyle.size + " Arial";
								context.fillStyle = _self.fontStyle.color;
								// context.strokeText(this.inputWord, 3*size / 8, 3*size / 8);
								// context.fillText(this.inputWord, 3*size / 8, 3*size / 8);

								// 获取canvas的宽度和高度
								var canvasWidth = canvas.width;
								var canvasHeight = canvas.height;
								// 使用measureText()方法获取文字的宽度
								var textWidth = context.measureText(_self.inputWord).width;
								// 计算文字的起始位置，使其居中显示
								var x = (canvasWidth - textWidth) / 2;
								var y = canvasHeight / 2;
								// 使用fillText()方法在canvas中插入文字
								context.strokeText(_self.inputWord, x, y);
								context.fillText(_self.inputWord, x, y);
							}
						}
					}
				};
			});
		},
		// 是否带边框
		drawWithStyle(val) {
			if (val == 1) {
				this.drawQRCodeWithbg();
			} else {
				this.drawQRCode();
			}
		},
		drawWithStyleGet() {
			if (this.bgStyle == 1) {
				this.drawQRCodeWithbg();
			} else {
				this.drawQRCode();
			}
		},
		drawQRCodeWithbg() {
			let _self = this;
			const canvas = this.$refs.canvas;
			const context = canvas.getContext("2d");

			// 清空画布
			context.clearRect(0, 0, canvas.width, canvas.height);

			// 获取自定义参数
			const size = canvas.width;
			const color = this.color;
			const bgcolor = this.bgcolor;
			const qrLevel = this.otherSet.level;
			const marginSize = this.otherSet.marginSize;

			// 设置二维码外边距
			var qrMargin = 4;
			// 如果包含标识编号和型号，调整二维码外边距
			if (_self.infoList.includes("标识编号") && _self.infoList.includes("型号")) {
				qrMargin = 26;
			} else if (_self.infoList.includes("标识编号")) {
				qrMargin = 20;
			} else if (_self.infoList.includes("型号")) {
				qrMargin = 16;
			} else {
				qrMargin = 4;
			}
			var opts = {
				errorCorrectionLevel: qrLevel,
				type: "image/jpeg",
				width: size / 2 - qrMargin,
				quality: 0.3,
				margin: marginSize,
				color: {
					dark: color,
					light: bgcolor,
				},
			};

			// 新建dom img
			const img = new Image();
			if (this.bgColorStyle == 1) {
				img.src = _self.bgImageBlue;
			} else {
				img.src = _self.bgImageRed;
			}

			img.onload = () => {
				// 绘制背景
				context.drawImage(img, 0, 0, size, size);

				//start 绘制圆角矩形
				context.fillStyle = "#fff"; // 设置填充颜色为白色
				context.strokeStyle = "#fff"; // 设置边框颜色为白色
				context.lineWidth = 2; // 设置边框宽度为2px
				var borderRadius = 6; // 设置圆角半径为12px
				var moveToTop = 15; // 设置移动距离为0

				context.beginPath();
				// moveTo(x,y)：将画笔移动到指定的坐标(x,y)
				context.moveTo(borderRadius + (size * 1) / 4, (size * 1) / 4 - moveToTop);
				// lineTo(x,y)：绘制一条从当前位置到指定坐标(x,y)的直线
				context.lineTo((size * 1) / 2 - borderRadius + (size * 1) / 4, (size * 1) / 4 - moveToTop);
				// arcTo(x1,y1,x2,y2,r)：绘制一条从当前位置到指定坐标(x2,y2)的弧线，其中r为圆弧半径,(x1,y1)为圆弧的控制点[直角点]
				context.arcTo((size * 1) / 2 + (size * 1) / 4, (size * 1) / 4 - moveToTop, (size * 1) / 2 + (size * 1) / 4, borderRadius + (size * 1) / 4 - moveToTop, borderRadius);
				context.lineTo((size * 1) / 2 + (size * 1) / 4, (size * 1) / 2 + (size * 1) / 4 - borderRadius - moveToTop);
				context.arcTo((size * 1) / 2 + (size * 1) / 4, (size * 1) / 2 + (size * 1) / 4 - moveToTop, (size * 1) / 2 + (size * 1) / 4 - borderRadius, (size * 1) / 2 + (size * 1) / 4 - moveToTop, borderRadius);
				context.lineTo((size * 1) / 4 + borderRadius, (size * 1) / 2 + (size * 1) / 4 - moveToTop);
				context.arcTo((size * 1) / 4, (size * 1) / 2 + (size * 1) / 4 - moveToTop, (size * 1) / 4, (size * 1) / 2 + (size * 1) / 4 - borderRadius - moveToTop, borderRadius);
				context.lineTo((size * 1) / 4, (size * 1) / 4 + borderRadius - moveToTop);
				context.arcTo((size * 1) / 4, (size * 1) / 4 - moveToTop, (size * 1) / 4 + borderRadius, (size * 1) / 4 - moveToTop, borderRadius);
				context.closePath();

				context.fill(); // 填充矩形
				context.stroke(); // 描边矩形
				//end 绘制圆角矩形

				//绘制中心二维码: 新建dom canvas2
				const canvas2 = document.createElement("canvas");
				const context2 = canvas2.getContext("2d");
				// 设置canvas宽高:中心二维码绘制画板
				canvas2.width = canvas.width / 2 - qrMargin;
				canvas2.height = canvas.height / 2 - qrMargin;
				QRCode.toDataURL(_self.content, opts, (err, url) => {
					if (err) throw err;

					const img = new Image();
					img.src = url;
					img.onload = () => {
						let size2 = canvas2.width;
						context2.drawImage(img, 0, 0, size2, size2);

						// 信息设置
						if (_self.infoList.includes("标识编号")) {
							// context绘制文字
							var content1 = "";
							var content2 = "";
							if (_self.content.length > 26) {
								content1 = _self.content.substring(0, 26);
								content2 = _self.content.substring(26);
							}

							let marginBottom = 0;
							if (_self.infoList.includes("型号")) {
								marginBottom = 26;
							} else {
								marginBottom = 20;
							}

							context.font = "8px Arial";
							context.fillStyle = "#000";
							var canvasWidth = canvas.width;
							// 使用measureText()方法获取文字的宽度
							var textWidth1 = context.measureText(content1).width;

							// 计算文字的起始位置，使其居中显示
							var x1 = (canvasWidth - textWidth1) / 2;
							var y1 = (size * 3) / 4 - (marginBottom + 8);
							// 使用fillText()方法在canvas中插入文字
							context.strokeText(content1, x1, y1);
							context.fillText(content1, x1, y1);

							context.font = "8px Arial";
							context.fillStyle = "#000";
							var textWidth2 = context.measureText(content2).width;
							var x2 = (canvasWidth - textWidth2) / 2;
							var y2 = (size * 3) / 4 - marginBottom;
							context.strokeText(content2, x2, y2);
							context.fillText(content2, x2, y2);
						}
						if (this.infoList.includes("型号")) {
							let marginBottom = 0;
							if (_self.infoList.includes("标识编号")) {
								marginBottom = 18;
							} else {
								marginBottom = 20;
							}
							// context绘制文字
							context.font = "8px Arial";
							context.fillStyle = "#000";
							var canvasWidth = canvas.width;
							// 使用measureText()方法获取文字的宽度
							var textWidth = context.measureText("型号：" + _self.model).width;
							// 计算文字的起始位置，使其居中显示
							var x = (canvasWidth - textWidth) / 2;
							var y = (size * 3) / 4 - marginBottom;
							// 使用fillText()方法在canvas中插入文字
							context.strokeText("型号：" + _self.model, x, y);
							context.fillText("型号：" + _self.model, x, y);
						}

						let isUrl = false;
						//判断_self.image是否是string
						if (typeof _self.image == "string") {
							isUrl = true;
						}

						// 图片&&文字设置
						if (_self.image) {
							// 圆角
							if (_self.picStyle == 1) {
								const radius = size2 / 6;
								context2.save();
								context2.beginPath();
								context2.arc(size2 / 2, size2 / 2, radius, 0, 2 * Math.PI);
								context2.clip();
								if (isUrl) {
									const imgs = new Image();
									imgs.src = _self.image;
									imgs.setAttribute("crossOrigin", "anonymous");
									imgs.onload = () => {
										context2.drawImage(imgs, (3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
										context2.restore();
										drawWords();
									};
								} else {
									context2.drawImage(_self.image, (3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
									context2.restore();
									drawWords();
								}
							} else if (_self.picStyle == 2) {
								const borderWidth = size2 / 40;
								// console.log(888, _self.image);
								if (isUrl) {
									const imgs = new Image();
									imgs.src = _self.image;
									imgs.setAttribute("crossOrigin", "anonymous");
									imgs.onload = () => {
										context2.drawImage(imgs, (3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
										context2.strokeStyle = "white";
										context2.lineWidth = borderWidth;
										context2.strokeRect((3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
										drawWords();
									};
								} else {
									context2.drawImage(_self.image, (3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
									context2.strokeStyle = "white";
									context2.lineWidth = borderWidth;
									context2.strokeRect((3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
									drawWords();
								}
							} else {
								if (isUrl) {
									const imgs = new Image();
									imgs.src = _self.image;
									imgs.setAttribute("crossOrigin", "anonymous");
									imgs.onload = () => {
										context2.drawImage(imgs, (3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
										drawWords();
									};
								} else {
									context2.drawImage(_self.image, (3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
									drawWords();
								}
							}
						} else {
							drawWords();
						}
						function drawWords() {
							if (_self.inputWord) {
								if (_self.fontStyle.style == "stroke") {
									context2.strokeStyle = "white";
									context2.lineWidth = 10;
									context2.font = _self.fontStyle.size + " Arial";
									context2.fillStyle = _self.fontStyle.color;
									// context2.strokeText(this.inputWord, 3*size / 8, 3*size / 8);
									// context2.fillText(this.inputWord, 3*size / 8, 3*size / 8);

									// 获取canvas的宽度和高度
									var canvasWidth = canvas2.width;
									var canvasHeight = canvas2.width;
									// 使用measureText()方法获取文字的宽度
									var textWidth = context2.measureText(_self.inputWord).width;
									// 计算文字的起始位置，使其居中显示
									var x = (canvasWidth - textWidth) / 2;
									var y = canvasHeight / 2;
									// 使用fillText()方法在canvas中插入文字
									context2.strokeText(_self.inputWord, x, y);
									context2.fillText(_self.inputWord, x, y);
									// // 将canvas2绘制到canvas中
									// context.drawImage(canvas2, (size * 2) / 3, (size * 2) / 3);
								}
							}
							// 将canvas2绘制到canvas中
							context.drawImage(canvas2, (size * 1) / 4 + qrMargin / 2, (size * 1) / 4 - 15);
						}
					};
				});
			};
		},
		str2drawQrCode() {
			const canvas = this.$refs.canvas;
			const context = canvas.getContext("2d");
			const size = canvas.width;
			const color = this.color;
			const bgcolor = this.bgcolor;
			const qrLevel = this.otherSet.level;
			const marginSize = this.otherSet.marginSize;
			var opts = {
				errorCorrectionLevel: qrLevel,
				type: "image/jpeg",
				width: size,
				quality: 0.3,
				margin: marginSize,
				color: {
					dark: color,
					// dark:`linear-gradient(90deg, #47479d 0%, red 100%);`,
					light: bgcolor,
				},
			};

			QRCode.toDataURL(this.content, opts, (err, url) => {
				if (err) throw err;

				let _self = this;
				const img = new Image();
				img.src = url;
				img.onload = () => {
					context.drawImage(img, 0, 0, size, size);

					if (this.image) {
						// 圆角
						if (this.picStyle == 1) {
							const radius = size / 6;
							context.save();
							context.beginPath();
							context.arc(size / 2, size / 2, radius, 0, 2 * Math.PI);
							context.clip();
							const imgs = new Image();
							imgs.src = this.image;
							imgs.setAttribute("crossOrigin", "anonymous");
							imgs.onload = () => {
								context.drawImage(imgs, (3 * size) / 8, (3 * size) / 8, size / 4, size / 4);
								context.restore();
								drawText();
							};
						} else if (this.picStyle == 2) {
							const borderWidth = size / 40;
							const imgs = new Image();
							imgs.src = this.image;
							imgs.setAttribute("crossOrigin", "anonymous");
							imgs.onload = () => {
								context.drawImage(imgs, (3 * size) / 8, (3 * size) / 8, size / 4, size / 4);
								context.strokeStyle = "white";
								context.lineWidth = borderWidth;
								context.strokeRect((3 * size) / 8, (3 * size) / 8, size / 4, size / 4);
								drawText();
							};
						} else {
							const imgs = new Image();
							imgs.src = this.image;
							imgs.setAttribute("crossOrigin", "anonymous");
							imgs.onload = () => {
								context.drawImage(imgs, (3 * size) / 8, (3 * size) / 8, size / 4, size / 4);
								drawText();
							};
						}
					} else {
						drawText();
					}
				};
				function drawText() {
					if (_self.inputWord) {
						if (_self.fontStyle.style == "stroke") {
							context.strokeStyle = "white";
							context.lineWidth = 10;
							context.font = _self.fontStyle.size + " Arial";
							context.fillStyle = _self.fontStyle.color;
							// context.strokeText(this.inputWord, 3*size / 8, 3*size / 8);
							// context.fillText(this.inputWord, 3*size / 8, 3*size / 8);

							// 获取canvas的宽度和高度
							var canvasWidth = canvas.width;
							var canvasHeight = canvas.height;
							// 使用measureText()方法获取文字的宽度
							var textWidth = context.measureText(_self.inputWord).width;
							// 计算文字的起始位置，使其居中显示
							var x = (canvasWidth - textWidth) / 2;
							var y = canvasHeight / 2;
							// 使用fillText()方法在canvas中插入文字
							context.strokeText(_self.inputWord, x, y);
							context.fillText(_self.inputWord, x, y);
						} else {
						}
					}
				}
			});
		},
		str2drawQrCodeWithbg() {
			let _self = this;
			const canvas = this.$refs.canvas;
			const context = canvas.getContext("2d");

			// 清空画布
			context.clearRect(0, 0, canvas.width, canvas.height);

			// 获取自定义参数
			const size = canvas.width;
			const color = this.color;
			const bgcolor = this.bgcolor;
			const qrLevel = this.otherSet.level;
			const marginSize = this.otherSet.marginSize;

			// 设置二维码外边距
			var qrMargin = 4;
			// 如果包含标识编号和型号，调整二维码外边距
			if (_self.infoList.includes("标识编号") && _self.infoList.includes("型号")) {
				qrMargin = 26;
			} else if (_self.infoList.includes("标识编号")) {
				qrMargin = 20;
			} else if (_self.infoList.includes("型号")) {
				qrMargin = 16;
			} else {
				qrMargin = 4;
			}
			var opts = {
				errorCorrectionLevel: qrLevel,
				type: "image/jpeg",
				width: size / 2 - qrMargin,
				quality: 0.3,
				margin: marginSize,
				color: {
					dark: color,
					light: bgcolor,
				},
			};

			// 新建dom img
			const img = new Image();
			if (this.bgColorStyle == 1) {
				img.src = _self.bgImageBlue;
			} else {
				img.src = _self.bgImageRed;
			}

			img.onload = () => {
				// 绘制背景
				context.drawImage(img, 0, 0, size, size);

				//start 绘制圆角矩形
				context.fillStyle = "#fff"; // 设置填充颜色为白色
				context.strokeStyle = "#fff"; // 设置边框颜色为白色
				context.lineWidth = 2; // 设置边框宽度为2px
				var borderRadius = 6; // 设置圆角半径为12px
				var moveToTop = 12; // 设置移动距离为0

				context.beginPath();
				// moveTo(x,y)：将画笔移动到指定的坐标(x,y)
				context.moveTo(borderRadius + (size * 1) / 4, (size * 1) / 4 - moveToTop);
				// lineTo(x,y)：绘制一条从当前位置到指定坐标(x,y)的直线
				context.lineTo((size * 1) / 2 - borderRadius + (size * 1) / 4, (size * 1) / 4 - moveToTop);
				// arcTo(x1,y1,x2,y2,r)：绘制一条从当前位置到指定坐标(x2,y2)的弧线，其中r为圆弧半径,(x1,y1)为圆弧的控制点[直角点]
				context.arcTo((size * 1) / 2 + (size * 1) / 4, (size * 1) / 4 - moveToTop, (size * 1) / 2 + (size * 1) / 4, borderRadius + (size * 1) / 4 - moveToTop, borderRadius);
				context.lineTo((size * 1) / 2 + (size * 1) / 4, (size * 1) / 2 + (size * 1) / 4 - borderRadius - moveToTop);
				context.arcTo((size * 1) / 2 + (size * 1) / 4, (size * 1) / 2 + (size * 1) / 4 - moveToTop, (size * 1) / 2 + (size * 1) / 4 - borderRadius, (size * 1) / 2 + (size * 1) / 4 - moveToTop, borderRadius);
				context.lineTo((size * 1) / 4 + borderRadius, (size * 1) / 2 + (size * 1) / 4 - moveToTop);
				context.arcTo((size * 1) / 4, (size * 1) / 2 + (size * 1) / 4 - moveToTop, (size * 1) / 4, (size * 1) / 2 + (size * 1) / 4 - borderRadius - moveToTop, borderRadius);
				context.lineTo((size * 1) / 4, (size * 1) / 4 + borderRadius - moveToTop);
				context.arcTo((size * 1) / 4, (size * 1) / 4 - moveToTop, (size * 1) / 4 + borderRadius, (size * 1) / 4 - moveToTop, borderRadius);
				context.closePath();

				context.fill(); // 填充矩形
				context.stroke(); // 描边矩形
				//end 绘制圆角矩形

				//绘制中心二维码: 新建dom canvas2
				const canvas2 = document.createElement("canvas");
				const context2 = canvas2.getContext("2d");
				// 设置canvas宽高:中心二维码绘制画板
				canvas2.width = canvas.width / 2 - qrMargin;
				canvas2.height = canvas.height / 2 - qrMargin;
				QRCode.toDataURL(_self.content, opts, (err, url) => {
					if (err) throw err;

					const img = new Image();
					img.src = url;
					img.onload = () => {
						let size2 = canvas2.width;
						context2.drawImage(img, 0, 0, size2, size2);

						// 信息设置
						if (_self.infoList.includes("标识编号")) {
							// context绘制文字
							var content1 = "";
							var content2 = "";
							if (_self.content.length > 26) {
								content1 = _self.content.substring(0, 26);
								content2 = _self.content.substring(26);
							}

							let marginBottom = 0;
							if (_self.infoList.includes("型号")) {
								marginBottom = 26;
							} else {
								marginBottom = 20;
							}

							context.font = "5px Arial";
							context.fillStyle = "#000";
							var canvasWidth = canvas.width;
							// 使用measureText()方法获取文字的宽度
							var textWidth1 = context.measureText(content1).width;

							// 计算文字的起始位置，使其居中显示
							var x1 = (canvasWidth - textWidth1) / 2;
							var y1 = (size * 3) / 4 - (marginBottom + 4);
							// 使用fillText()方法在canvas中插入文字
							context.strokeText(content1, x1, y1);
							context.fillText(content1, x1, y1);

							context.font = "5px Arial";
							context.fillStyle = "#000";
							var textWidth2 = context.measureText(content2).width;
							var x2 = (canvasWidth - textWidth2) / 2;
							var y2 = (size * 3) / 4 - (marginBottom - 2);
							context.strokeText(content2, x2, y2);
							context.fillText(content2, x2, y2);
						}
						if (this.infoList.includes("型号")) {
							let marginBottom = 0;
							if (_self.infoList.includes("标识编号")) {
								marginBottom = 18;
							} else {
								marginBottom = 20;
							}
							// context绘制文字
							context.font = "6px Arial";
							context.fillStyle = "#000";
							var canvasWidth = canvas.width;
							// 使用measureText()方法获取文字的宽度
							var textWidth = context.measureText("型号：" + _self.model).width;
							// 计算文字的起始位置，使其居中显示
							var x = (canvasWidth - textWidth) / 2;
							var y = (size * 3) / 4 - marginBottom;
							// 使用fillText()方法在canvas中插入文字
							context.strokeText("型号：" + _self.model, x, y);
							context.fillText("型号：" + _self.model, x, y);
						}

						// 图片&&文字设置
						if (_self.image) {
							// 圆角
							if (_self.picStyle == 1) {
								const radius = size2 / 6;
								context2.save();
								context2.beginPath();
								context2.arc(size2 / 2, size2 / 2, radius, 0, 2 * Math.PI);
								context2.clip();
								const imgs = new Image();
								imgs.src = _self.image;
								imgs.setAttribute("crossOrigin", "anonymous");
								imgs.onload = () => {
									context2.drawImage(imgs, (3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
									context2.restore();
									newFunction(context, context2, canvas2);
								};
								// context2.drawImage(_self.image, (3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
								// context2.restore();
							} else if (_self.picStyle == 2) {
								const borderWidth = size2 / 40;
								const imgs = new Image();
								imgs.src = _self.image;
								imgs.setAttribute("crossOrigin", "anonymous");
								imgs.onload = () => {
									context2.drawImage(imgs, (3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
									context2.strokeStyle = "white";
									context2.lineWidth = borderWidth;
									context2.strokeRect((3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
									newFunction(context, context2, canvas2);
								};
							} else {
								const imgs = new Image();
								imgs.src = _self.image;
								imgs.setAttribute("crossOrigin", "anonymous");
								imgs.onload = () => {
									context2.drawImage(imgs, (3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
									newFunction(context, context2, canvas2);
									// context2.restore();
								};
								// context2.drawImage(_self.image, (3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
							}
						} else {
							newFunction(context, context2, canvas2);
						}
					};
				});
			};

			function newFunction(context, context2, canvas2) {
				if (_self.inputWord) {
					if (_self.fontStyle.style == "stroke") {
						context2.strokeStyle = "white";
						context2.lineWidth = 10;
						// 放缩字体
						context2.font = _self.fontStyle.size - 8 + " Arial";
						context2.fillStyle = _self.fontStyle.color;
						// context2.strokeText(this.inputWord, 3*size / 8, 3*size / 8);
						// context2.fillText(this.inputWord, 3*size / 8, 3*size / 8);
						// 获取canvas的宽度和高度
						var canvasWidth = canvas2.width;
						var canvasHeight = canvas2.width;
						// 使用measureText()方法获取文字的宽度
						var textWidth = context2.measureText(_self.inputWord).width;
						// 计算文字的起始位置，使其居中显示
						var x = (canvasWidth - textWidth) / 2;
						var y = canvasHeight / 2;
						// 使用fillText()方法在canvas中插入文字
						context2.strokeText(_self.inputWord, x, y);
						context2.fillText(_self.inputWord, x, y);
						// // 将canvas2绘制到canvas中
						// context.drawImage(canvas2, (size * 2) / 3, (size * 2) / 3);
					}
				}
				// 将canvas2绘制到canvas中
				context.drawImage(canvas2, (size * 1) / 4 + qrMargin / 2, (size * 1) / 4 - 10);
			}
		},
		async drawDownQRCode(content, paramStr, sign, modelName) {
			let params = JSON.parse(paramStr);
			// const canvas = this.$refs.canvas;
			const canvas = document.createElement("canvas");
			canvas.width = 500;
			canvas.height = 500;
			const context = canvas.getContext("2d");
			const size = 300;
			const color = params.color;
			const bgcolor = params.bgcolor;
			const qrLevel = params.otherSet.level;
			const marginSize = params.otherSet.marginSize;
			var opts = {
				errorCorrectionLevel: qrLevel,
				type: "image/jpeg",
				width: size,
				quality: 0.3,
				margin: marginSize,
				color: {
					dark: color,
					// dark:`linear-gradient(90deg, #47479d 0%, red 100%);`,
					light: bgcolor,
				},
			};

			const result = await this.waitQrCode(content, opts, context, size, params, canvas, sign, modelName);
			return result;
		},
		async waitQrCode(content, opts, context, size, params, canvas, sign, modelName) {
			let _self = this;
			return new Promise((resolve, reject) => {
				QRCode.toDataURL(content, opts, (err, url) => {
					if (err) reject(err);

					const img = new Image();
					img.src = url;
					img.setAttribute("crossOrigin", "anonymous");
					img.onload = () => {
						context.drawImage(img, 100, 10, size, size);
						// context绘制分割线
						context.beginPath();
						context.moveTo(0, size + 20);
						context.lineTo(canvas.width, size + 20);
						context.lineWidth = 1;
						context.strokeStyle = "#9a9a9a";
						context.stroke();
						// context绘制文字
						context.font = "20px Arial";
						context.fillStyle = "#606266";
						var canvasWidth = canvas.width;
						// 使用measureText()方法获取文字的宽度
						var textWidth = context.measureText(sign).width;
						// 计算文字的起始位置，使其居中显示
						var x = (canvasWidth - textWidth) / 2;
						var y = size + 50;
						// 使用fillText()方法在canvas中插入文字
						context.strokeText(sign, x, y);
						context.fillText(sign, x, y);
						// context绘制文字
						context.font = "20px Arial";
						context.fillStyle = "#606266";
						var canvasWidth = canvas.width;
						// 使用measureText()方法获取文字的宽度
						var textWidth = context.measureText("型号：" + modelName).width;
						// 计算文字的起始位置，使其居中显示
						var x = (canvasWidth - textWidth) / 2;
						var y = size + 80;
						// 使用fillText()方法在canvas中插入文字
						context.strokeText("型号：" + modelName, x, y);
						context.fillText("型号：" + modelName, x, y);

						let promisePic1 = null;

						if (params.image) {
							// let frankImg = document.createElement('img');
							// frankImg.setAttribute('src', params.image)

							// 圆角
							if (params.picStyle == 1) {
								const radius = size / 6;
								context.save();
								context.beginPath();
								context.arc(canvas.width / 2, size / 2, radius, 0, 2 * Math.PI);
								context.clip();
								const imgs = new Image();
								imgs.src = params.image;
								imgs.setAttribute("crossOrigin", "anonymous");
								promisePic1 = new Promise((resolve, reject) => {
									imgs.onload = () => {
										context.drawImage(params.image, (canvas.width - size / 4) / 2, (3 * size) / 8, size / 4, size / 4);
										// context.drawImage(frankImg, (3 * size) / 8, (3 * size) / 8, size / 4, size / 4);
										context.restore();
										newFunction(canvas);
										resolve();
									};
									imgs.onerror = (err) => {
										reject(err);
									};
								});
							} else if (params.picStyle == 2) {
								const borderWidth = size / 40;
								const imgs = new Image();
								imgs.src = params.image;
								imgs.setAttribute("crossOrigin", "anonymous");
								promisePic1 = new Promise((resolve, reject) => {
									imgs.onload = () => {
										context.drawImage(params.image, (canvas.width - size / 4) / 2, (3 * size) / 8, size / 4, size / 4);
										// context.drawImage(frankImg, (3 * size) / 8, (3 * size) / 8, size / 4, size / 4);
										context.strokeStyle = "white";
										context.lineWidth = borderWidth;
										context.strokeRect((canvas.width - size / 4) / 2, (3 * size) / 8, size / 4, size / 4);
										newFunction(canvas);
										resolve();
									};
									imgs.onerror = (err) => {
										reject(err);
									};
								});
							} else {
								// _self.drawImageAsync(params,context,size)
								const imgs = new Image();
								imgs.src = params.image;
								imgs.setAttribute("crossOrigin", "anonymous");
								promisePic1 = new Promise((resolve, reject) => {
									imgs.onload = () => {
										context.drawImage(imgs, (canvas.width - size / 4) / 2, (3 * size) / 8, size / 4, size / 4);
										newFunction(canvas);
										resolve();
									};
									imgs.onerror = (err) => {
										reject(err);
									};
								});

								// context.drawImage(frankImg, (3 * size) / 8, (3 * size) / 8, size / 4, size / 4);

								// promisePic1 = new Promise((resolve, reject) => {
								// 	imgs.onload = () => {
								// 		context.drawImage(imgs, (3 * size) / 8, (3 * size) / 8, size / 4, size / 4);
								// 			resolve();
								// 	};
								// 	imgs.onerror = (err) => {
								// 	reject(err);
								// 	};
								// });
							}
						} else {
							newFunction(canvas);
						}

						Promise.all([promisePic1]).then(() => {
							// img绘制完成之后 return canvas.toDataURL("image/png")作为waitQrCode的返回值,放大1.5倍
							context.scale(2, 2);
							resolve(canvas.toDataURL("image/png"));
						});
					};
				});
			});

			function newFunction(canvas) {
				if (params.inputWord) {
					if (params.fontStyle.style == "stroke") {
						context.strokeStyle = "white";
						context.lineWidth = 10;
						context.font = params.fontStyle.size + " Arial";
						context.fillStyle = params.fontStyle.color;
						// context.strokeText(params.inputWord, 3*size / 8, 3*size / 8);
						// context.fillText(params.inputWord, 3*size / 8, 3*size / 8);
						// 获取canvas的宽度和高度
						var canvasWidth = canvas.width;
						var canvasHeight = 300;
						// 使用measureText()方法获取文字的宽度
						var textWidth = context.measureText(params.inputWord).width;
						// 计算文字的起始位置，使其居中显示
						var x = (canvasWidth - textWidth) / 2;
						var y = canvasHeight / 2;
						// 使用fillText()方法在canvas中插入文字
						context.strokeText(params.inputWord, x, y);
						context.fillText(params.inputWord, x, y);
					}
				}
			}
		},
		async drawDownQRCodeWithBg(content, paramStr, sign, modelName) {
			let params = JSON.parse(paramStr);
			// const canvas = this.$refs.canvas;
			const canvas = document.createElement("canvas");
			canvas.width = 300 * 2;
			canvas.height = 300 * 2;
			const context = canvas.getContext("2d");
			const size = 300 * 2;
			const color = params.color;
			const bgcolor = params.bgcolor;
			const qrLevel = params.otherSet.level;
			const marginSize = params.otherSet.marginSize;
			const infoList = params.infoList;
			const bgColorStyle = params.bgColorStyle;

			// 设置二维码外边距
			var qrMargin = 4;
			// 如果包含标识编号和型号，调整二维码外边距
			if (infoList.includes("标识编号") && infoList.includes("型号")) {
				qrMargin = 26;
			} else if (infoList.includes("标识编号")) {
				qrMargin = 20 * 1.2;
			} else if (infoList.includes("型号")) {
				qrMargin = 16 * 1.2;
			} else {
				qrMargin = 4;
			}

			var opts = {
				errorCorrectionLevel: qrLevel,
				type: "image/jpeg",
				width: size / 2 - qrMargin * 2.1,
				quality: 0.3,
				margin: marginSize,
				color: {
					dark: color,
					// dark:`linear-gradient(90deg, #47479d 0%, red 100%);`,
					light: bgcolor,
				},
			};

			const result = await this.waitQrCodeWithBg(content, opts, context, size, params, canvas, sign, modelName, bgColorStyle, infoList, qrMargin);
			return result;
		},
		async waitQrCodeWithBg(content, opts, context, size, params, canvas, sign, modelName, bgColorStyle, infoList, qrMargin) {
			let _self = this;
			return new Promise((resolve, reject) => {
				// 新建dom img
				const img = new Image();
				if (bgColorStyle == 1) {
					img.src = _self.bgImageBlue;
				} else {
					img.src = _self.bgImageRed;
				}

				img.onload = () => {
					// 绘制背景
					context.drawImage(img, 0, 30, size, size - 30);

					//start 绘制圆角矩形
					context.fillStyle = "#fff"; // 设置填充颜色为白色
					context.strokeStyle = "#fff"; // 设置边框颜色为白色
					context.lineWidth = 2; // 设置边框宽度为2px
					var borderRadius = 6; // 设置圆角半径为12px
					var moveToTop = 15; // 设置移动距离为0

					context.beginPath();
					// moveTo(x,y)：将画笔移动到指定的坐标(x,y)
					context.moveTo(borderRadius + (size * 1) / 4, (size * 1) / 4 - moveToTop);
					// lineTo(x,y)：绘制一条从当前位置到指定坐标(x,y)的直线
					context.lineTo((size * 1) / 2 - borderRadius + (size * 1) / 4, (size * 1) / 4 - moveToTop);
					// arcTo(x1,y1,x2,y2,r)：绘制一条从当前位置到指定坐标(x2,y2)的弧线，其中r为圆弧半径,(x1,y1)为圆弧的控制点[直角点]
					context.arcTo((size * 1) / 2 + (size * 1) / 4, (size * 1) / 4 - moveToTop, (size * 1) / 2 + (size * 1) / 4, borderRadius + (size * 1) / 4 - moveToTop, borderRadius);
					context.lineTo((size * 1) / 2 + (size * 1) / 4, (size * 1) / 2 + (size * 1) / 4 - borderRadius - moveToTop);
					context.arcTo((size * 1) / 2 + (size * 1) / 4, (size * 1) / 2 + (size * 1) / 4 - moveToTop, (size * 1) / 2 + (size * 1) / 4 - borderRadius, (size * 1) / 2 + (size * 1) / 4 - moveToTop, borderRadius);
					context.lineTo((size * 1) / 4 + borderRadius, (size * 1) / 2 + (size * 1) / 4 - moveToTop);
					context.arcTo((size * 1) / 4, (size * 1) / 2 + (size * 1) / 4 - moveToTop, (size * 1) / 4, (size * 1) / 2 + (size * 1) / 4 - borderRadius - moveToTop, borderRadius);
					context.lineTo((size * 1) / 4, (size * 1) / 4 + borderRadius - moveToTop);
					context.arcTo((size * 1) / 4, (size * 1) / 4 - moveToTop, (size * 1) / 4 + borderRadius, (size * 1) / 4 - moveToTop, borderRadius);
					context.closePath();

					context.fill(); // 填充矩形
					context.stroke(); // 描边矩形
					//end 绘制圆角矩形

					//绘制中心二维码: 新建dom canvas2
					const canvas2 = document.createElement("canvas");
					const context2 = canvas2.getContext("2d");
					// 设置canvas宽高:中心二维码绘制画板
					canvas2.width = canvas.width / 2 - qrMargin * 1.5;
					canvas2.height = canvas.height / 2 - qrMargin * 1.5;
					QRCode.toDataURL(content, opts, (err, url) => {
						if (err) throw err;

						const img = new Image();
						img.src = url;
						img.onload = () => {
							let size2 = canvas2.width;
							context2.drawImage(img, 3, 0, size2, size2);

							// 信息设置
							if (infoList.includes("标识编号")) {
								// context绘制文字
								var content1 = "";
								var content2 = "";
								if (sign.length > 36) {
									content1 = sign.substring(0, 36);
									content2 = sign.substring(36);
								} else {
									content1 = sign;
								}

								let marginBottom = 0;
								if (infoList.includes("型号")) {
									marginBottom = 26;
								} else {
									marginBottom = 20;
								}

								context.font = "15px Arial";
								context.fillStyle = "#000";
								var canvasWidth = canvas.width;
								// 使用measureText()方法获取文字的宽度
								var textWidth1 = context.measureText(content1).width;

								// 计算文字的起始位置，使其居中显示
								var x1 = (canvasWidth - textWidth1) / 2;
								var y1 = (size * 3) / 4 - (marginBottom + 17);
								// 使用fillText()方法在canvas中插入文字
								context.strokeText(content1, x1, y1);
								context.fillText(content1, x1, y1);

								context.font = "15px Arial";
								context.fillStyle = "#000";
								var textWidth2 = context.measureText(content2).width;
								var x2 = (canvasWidth - textWidth2) / 2;
								var y2 = (size * 3) / 4 - (marginBottom + 5);
								context.strokeText(content2, x2, y2);
								context.fillText(content2, x2, y2);
							}
							if (infoList.includes("型号")) {
								let marginBottom = 0;
								if (infoList.includes("标识编号")) {
									marginBottom = 18;
								} else {
									marginBottom = 20;
								}
								// context绘制文字
								context.font = "15px Arial";
								context.fillStyle = "#000";
								var canvasWidth = canvas.width;
								// 使用measureText()方法获取文字的宽度
								var textWidth = context.measureText("型号：" + modelName).width;
								if (textWidth > canvas2.width) {
									context.font = "15px Arial";
									textWidth = context.measureText("型号：" + modelName).width;
								}
								// 计算文字的起始位置，使其居中显示
								var x = (canvasWidth - textWidth) / 2;
								var y = (size * 3) / 4 - marginBottom;
								// 使用fillText()方法在canvas中插入文字
								context.strokeText("型号：" + modelName, x, y);
								context.fillText("型号：" + modelName, x, y);
							}

							let promisePic1 = null;
							// 图片&&文字设置
							if (params.image) {
								// 圆角
								if (params.picStyle == 1) {
									const radius = size2 / 6;
									context2.save();
									context2.beginPath();
									context2.arc(size2 / 2, size2 / 2, radius, 0, 2 * Math.PI);
									context2.clip();
									const imgs = new Image();
									imgs.src = params.image;
									imgs.setAttribute("crossOrigin", "Anonymous");
									promisePic1 = new Promise((resolve, reject) => {
										imgs.onload = () => {
											context2.drawImage(imgs, (3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
											context2.resolve();
											newFunction(canvas2, context2);
											// 将canvas2绘制到canvas中
											context.drawImage(canvas2, (size * 1) / 4 + qrMargin / 2, (size * 1) / 4 - 15);
											resolve();
										};
										imgs.onerror = (err) => {
											reject(err);
										};
									});
								} else if (params.picStyle == 2) {
									const borderWidth = size2 / 40;
									const imgs = new Image();
									imgs.src = params.image;
									imgs.setAttribute("crossOrigin", "Anonymous");
									promisePic1 = new Promise((resolve, reject) => {
										imgs.onload = () => {
											context2.drawImage(imgs, (3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
											context2.strokeStyle = "white";
											context2.lineWidth = borderWidth;
											context2.strokeRect((3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
											newFunction(canvas2, context2);
											// 将canvas2绘制到canvas中
											context.drawImage(canvas2, (size * 1) / 4 + qrMargin / 2, (size * 1) / 4 - 15);
											resolve();
										};
										imgs.onerror = (err) => {
											reject(err);
										};
									});
								} else {
									const imgs = new Image();
									imgs.src = params.image;
									imgs.setAttribute("crossOrigin", "Anonymous");
									promisePic1 = new Promise((resolve, reject) => {
										imgs.onload = () => {
											context2.drawImage(imgs, (3 * size2) / 8, (3 * size2) / 8, size2 / 4, size2 / 4);
											newFunction(canvas2, context2);
											// 将canvas2绘制到canvas中
											context.drawImage(canvas2, (size * 1) / 4 + qrMargin / 2, (size * 1) / 4 - 15);
											resolve();
										};
										imgs.onerror = (err) => {
											reject(err);
										};
									});
								}
							} else {
								newFunction(canvas2, context2);
								// 将canvas2绘制到canvas中
								context.drawImage(canvas2, (size * 1) / 4 + qrMargin / 2, (size * 1) / 4 - 15);
							}

							Promise.all([promisePic1]).then(() => {
								context.scale(2, 2);
								resolve(canvas.toDataURL("image/png"));
							});
						};
					});
				};
			});

			function newFunction(canvas2, context2) {
				if (params.inputWord) {
					if (params.fontStyle.style == "stroke") {
						context2.strokeStyle = "white";
						context2.lineWidth = 10;
						context2.font = params.fontStyle.size + " Arial";
						context2.fillStyle = params.fontStyle.color;
						// context2.strokeText(this.inputWord, 3*size / 8, 3*size / 8);
						// context2.fillText(this.inputWord, 3*size / 8, 3*size / 8);

						// 获取canvas的宽度和高度
						var canvasWidth = canvas2.width;
						var canvasHeight = canvas2.width;
						// 使用measureText()方法获取文字的宽度
						var textWidth = context2.measureText(params.inputWord).width;
						// 计算文字的起始位置，使其居中显示
						var x = (canvasWidth - textWidth) / 2;
						var y = canvasHeight / 2;
						// 使用fillText()方法在canvas中插入文字
						context2.strokeText(params.inputWord, x, y);
						context2.fillText(params.inputWord, x, y);
						// // 将canvas2绘制到canvas中
						// context.drawImage(canvas2, (size * 2) / 3, (size * 2) / 3);
					}
				}
			}
		},

		onFileChange(event) {
			let _self = this;
			const file = event.target.files[0];
			_self.imgFile = event.target.files[0];
			const reader = new FileReader();

			reader.onload = (event) => {
				const img = new Image();
				img.src = event.target.result;
				img.onload = () => {
					_self.image = img;
					_self.drawWithStyleGet();
				};
			};

			reader.readAsDataURL(file);
		},
	},
};
</script>

<style lang="scss">
.prefabCode {
	position: relative;

	.qrBorder {
		display: inline-block;
		width: 100px;
		text-align: end;
		padding-right: 20px;
	}

	.pContent {
		margin-top: 20px;
		text-align: center;
	}
	.pBtn {
		margin-left: 80px;
	}
}
</style>
